<template>
    <nav>
        <v-app-bar app dark height='65'>
            <v-toolbar-title @click="navigateHome" class='app-bar__title pa-2'>
                <v-avatar>
                    <img :src="avatar" :alt="`${custom.title} Logo`">
                </v-avatar>
                <span class="ml-4 px-2 py-1 app-bar__title__text">{{custom.title}}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class='hidden-sm-and-down'>
                <v-btn v-for='item in menuItems' text :key='item.route' :to="item.route">
                    <v-icon left>{{ item.icon }}</v-icon>
                    {{item.name}}
                </v-btn>
            </v-toolbar-items>
            <v-toolbar-items class='hidden-md-and-up'>
                <v-btn aria-label="Menu" text @click="$store.commit('SET_SHOWDIALOG', true)">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </v-toolbar-items>
            <v-dialog v-model="showDialog" app fullscreen dark hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark height='65'>
                        <v-toolbar-title @click="navigateHome" class='font-weight-light app-bar__title pa-2'>
                            <v-avatar>
                                <img :src="avatar" :alt="`${custom.title} Logo`">
                            </v-avatar>
                            <span class="ml-4 px-2 py-1 app-bar__title__text">{{custom.title}}</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn aria-label="close" icon dark @click="closeDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-list>
                        <v-list-item v-for="item in menuItems" :key="item.route" :to="item.route">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
        </v-app-bar>

    </nav>
</template>

<script>
    import {
        mapState
    } from 'vuex';

    export default {
        name: "Navbar",
        data() {
            return {
                img: 'face.jpg',
                menuItems: [{
                        name: 'Accueil',
                        icon: 'mdi-home',
                        route: '/'
                    },
                    {
                        name: 'Prestations',
                        icon: 'mdi-briefcase',
                        route: '/prestas'
                    },
                    {
                        name: 'Réalisations',
                        icon: 'mdi-playlist-check',
                        route: '/reals'
                    },
                    {
                        name: 'Infos pratiques',
                        icon: 'mdi-information',
                        route: '/infos'
                    },
                    {
                        name: 'Contact',
                        icon: 'mdi-email',
                        route: '/contact'
                    },
                ]
            }
        },
        computed: {
            ...mapState(['showDialog', 'custom']),
            avatar() {
                return require(`@/assets/img/${this.img}`);
            }
        },
        methods: {
            navigateHome() {
                if (this.$route.path !== '/') {
                    this.$router.push('/');
                }
            },
            closeDialog() {
                this.$store.commit('SET_SHOWDIALOG', false);
                if (window.document.activeElement) {
                    setTimeout(() => {
                        window.document.activeElement.blur();
                    }, 100);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

    .app-bar {
        &__title {
            font-family: 'Kaushan Script', cursive;
            user-select: none;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &__text {
                font-size: 1.25em;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
</style>