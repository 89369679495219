<template>
    <v-fab-transition>
        <v-btn aria-label="backtotop" v-if="show" dark fab fixed bottom right @click='gotoTop'>
            <v-icon>
                mdi-arrow-up
            </v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
    export default {
        name: "BackToTop",
        data() {
            return {
                show: false
            }
        },
        methods: {
            gotoTop() {
                this.$vuetify.goTo(0, {
                    duration: (window.scrollY / 2.5),
                    easing: 'easeInOutQuad'
                })
            }
        },
        mounted() {
            const that = this;
            window.addEventListener('scroll', () => {
                if (window.scrollY > 200) {
                    that.show = true;
                } else {
                    that.show = false;
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    button {
        opacity: 0.5;
    }
</style>