<template>
    <v-footer dark app absolute padless>
        <v-card flat tile width="100%" class="text-center">
            <v-card-text>
                <v-tooltip top v-for="icon in icons" :key="icon.name">
                    <template v-slot:activator='{ on }'>
                        <v-btn :aria-label="icon.tooltip" v-on='on' class="mx-3 mx-lg-5" icon
                            @click="navigate(icon.link)">
                            <v-icon size="24px">
                                {{ icon.name }}
                            </v-icon>
                        </v-btn>
                    </template>
                    {{icon.tooltip}}
                </v-tooltip>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>{{custom.title}}</strong> — ©
            </v-card-text>
        </v-card>
    </v-footer>



</template>

<script>
    import {
        mapState
    } from 'vuex';

    export default {
        name: "Footer",
        data() {
            return {
                icons: [{
                        name: 'mdi-linkedin',
                        link: 'https://www.linkedin.com/in/julienferand-dev/',
                        tooltip: 'LinkedIn'
                    },
                    {
                        name: 'mdi-handshake',
                        link: 'https://www.malt.fr/profile/julienferand',
                        tooltip: 'Malt'
                    },
                    {
                        name: 'mdi-github',
                        link: 'https://github.com/julienfdev',
                        tooltip: 'GitHub',
                    },
                    {
                        name: 'mdi-gavel',
                        link: 'router:/legal',
                        tooltip: 'Mentions Légales'
                    },
                    {
                        name: 'mdi-email',
                        link: 'router:/contact',
                        tooltip: 'Contact'
                    }
                ]
            }
        },
        computed: {
            ...mapState(['custom'])
        },
        methods: {
            navigate(url) {
                if (url.startsWith('router:')) {
                    if (this.$route.path !== url.replace('router:', '')) {
                        this.$router.push(url.replace('router:', ''))
                    }
                } else {
                    window.open(url, '_blank');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>