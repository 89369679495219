import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showDialog: false,
    showCookiesPanel: false,
    custom:{
      title: 'Julien Ferand'
    }
  },
  mutations: {
    SET_SHOWDIALOG(state, payload){
      state.showDialog = payload
    },
    SET_SHOWCOOKIES(state, payload){
      state.showCookiesPanel = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
