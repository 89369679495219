import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import routes from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scroll(0, 0);
  store.commit('SET_SHOWDIALOG', false);
});

export default router