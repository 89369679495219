const routes = [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/legal',
    name: 'MentionsLegales',
    component: () => import( /* webpackChunkName: "mentionslegales" */ '../views/Legal.vue')
  },
  {
    path: '/prestas',
    name: 'Prestas',
    component: () => import( /* webpackChunkName: "prestas" */ '../views/Prestas.vue'),
    children: [{
        path: '',
        redirect: 'web'
      },
      {
        path: 'web',
        components: {
          prestas: () => import( /* webpackChunkName: "web" */ '../components/prestas/Web.vue')
        },
      },
      {
        path: 'iot',
        components: {
          prestas: () => import( /* webpackChunkName: "iot" */ '../components/prestas/Iot.vue')
        }
      },
      {
        path: 'misc',
        components: {
          prestas: () => import( /* webpackChunkName: "misc" */ '../components/prestas/Misc.vue')
        }
      }
    ]
  },
  {
    path: '/reals',
    name: 'Reals',
    component: () => import( /* webpackChunkName: "reals" */ '../views/Reals.vue')
  },
  {
    path: '/infos',
    name: 'Infos',
    component: () => import( /* webpackChunkName: "infos" */ '../views/Infos.vue')
  }

]

module.exports = routes;