<template>
  <v-app>
    <BackToTop />
    <Navbar />
    <v-main class="main mb-10">
      <router-view></router-view>
    </v-main>
    <Footer />
    <Cookies />
  </v-app>
</template>
<script>
  import Navbar from '@/components/Navbar';
  import Footer from '@/components/Footer';
  import BackToTop from '@/components/BackToTop';
  import Cookies from '@/components/Cookies';

  export default {
    name: 'App',

    components: {
      Navbar,
      Footer,
      BackToTop,
      Cookies
    },

    data: () => ({

    }),
    methods:{
      checkCookies(){
        return localStorage.getItem('acceptCookies')
      }
    },
    mounted(){
      if(!this.checkCookies()){
        setTimeout(()=>{
        this.$store.commit('SET_SHOWCOOKIES', true)
        }, 1000);
      }
    }
  };
</script>

<style lang="scss">
  *::selection {
    background-color: darken(grey, 20);
    color: white;
  }
  a{
    text-decoration: none;
  }

</style>