import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueTelInputVuetify, {
  vuetify
})
Vue.use(VueAnalytics, {
  id: 'UA-177236828-2 ',
  router
})