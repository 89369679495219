<template>
  <v-bottom-sheet v-model="showCookiesPanel" persistent>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <p class="text-h5 font-weight-light">Préférences relatives à l'utilisation de cookies</p>
      </v-card-title>
      <v-card-text class="text-center">
        <p>
          Pour optimiser la navigation sur le site, certaines préférences peuvent être amenées à être stockées
          localement sur votre ordinateur<br>Conformément à la législation européenne, vous devez lors de votre première
          visite choisir votre comportement
          en rapport avec ces cookies</p>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-action class="d-flex justify-center flex-column flex-lg-row">
        <v-btn color="success darken-2" class="my-3 my-lg-0 mx-3" @click="acceptCookies">
          Accepter tout
        </v-btn>
        <v-btn color="secondary" class="mb-3 mx-3" @click="refuseCookies">
          Refuser tout
        </v-btn>
      </v-card-action>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
  import {
    mapState
  } from 'vuex';

  export default {
    name: "Cookies",
    data() {
      return {

      }
    },
    computed: {
      ...mapState(['showCookiesPanel'])
    },
    methods: {
      closePanel() {
        this.$store.commit('SET_SHOWCOOKIES', false);
      },
      acceptCookies() {
        localStorage.setItem('acceptCookies', true);
        this.$store.commit('SET_SHOWCOOKIES', false);
      },
      refuseCookies() {
        localStorage.removeItem('acceptCookies');
        this.$store.commit('SET_SHOWCOOKIES', false);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .v-card {
    &__title {
      word-break: break-word;
    }
  }
</style>